<template>
  <v-card>
    <v-container>
      <v-card-title class="mt-n3">
        <span class="primary--text">{{ newTitle }}</span>
      </v-card-title>
      <v-col
        v-if="!onlySeeVista"
        cols="12"
        class="p-0 pb-0 mt-n5 ml-4"
        tabindex="0"
      >
        <span class="error--text">{{ mensajeEditError }}</span>
      </v-col>
      <v-card-text>
        <v-form
          :disabled="onlySeeVista"
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="canSave()"
        >
          <v-row class="mt-0">
            <!-- cuentas bancarias -->
            <v-col cols="12" md="4" class="py-0 pr-4">
              <v-autocomplete
                ref="cuentas-autocomplete"
                v-model="cuentaSelected"
                :items="cuentasBanc"
                :search-input.sync="cuentaAutocompleteInput"
                item-text="bctaNombre"
                item-value="bctaId"
                :disabled="editMovimientoItem !== undefined || onlySeeVista"
                :filled="
                  editMovimientoItem !== undefined ||
                  canEditAllMovimiento ||
                  onlySeeVista
                "
                @change="changeCuentaSelect"
                :filter="customFilterCuentas"
                :rules="rules.required"
                outlined
                clearable
                return-object
                :hint="
                  editMovimientoItem !== undefined
                    ? ''
                    : 'Búsqueda por código de cuenta'
                "
                dense
                :loading="loadingBtn"
                autocomplete="off"
                label="Cuenta bancaria"
                persistent-hint
              >
                <template slot="item" slot-scope="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.bctaNombre }}</v-list-item-title>
                    <v-list-item-subtitle
                      >Código: {{ item.bctaCodigo }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-slot:append>
                  <v-progress-circular
                    indeterminate
                    size="28"
                    v-if="cuentasLoading"
                    color="primary"
                  ></v-progress-circular>
                </template>
              </v-autocomplete>
            </v-col>
            <!-- Tipo de movimiento -->
            <v-col cols="12" md="4" class="py-0 pl-0 pr-4">
              <v-autocomplete
                ref="tipo-movimiento"
                v-model="tipoMovimientoSelect"
                :items="tipoMovimientoItems"
                return-object
                item-text="tmbNombre"
                item-value="tmbId"
                :disabled="
                  editMovimientoItem !== undefined || canEditAllMovimiento
                "
                :filled="
                  editMovimientoItem !== undefined || canEditAllMovimiento
                "
                :rules="rules.required"
                outlined
                clearable
                @change="changeTipoMovimiento"
                dense
                autocomplete="off"
                label="Tipo de movimiento"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
            <!-- numero comprobante -->
            <v-col
              cols="4"
              :class="
                notEnterValor ? 'pb-0 py-0 pl-0 pr-n4' : 'pb-0 py-0 pl-0 pr-0'
              "
            >
              <v-text-field
                v-model.trim="numeroComprobante"
                type="text"
                label="Número comprobante"
                :disabled="canEditAllMovimiento || onlySeeVista"
                :filled="canEditAllMovimiento || onlySeeVista"
                :rules="
                  rules.required.concat([
                    rules.maxLength(numeroComprobante, 40),
                    rules.requiredTrim(),
                  ])
                "
                dense
                outlined
              >
                <template v-slot:append-outer>
                  <section v-if="notEnterValor">
                    <v-tooltip top max-width="30%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" v-bind="attrs" v-on="on">
                          {{ infoIcon }}
                        </v-icon>
                      </template>
                      <span>
                        No se puede guardar un movimiento bancario donde el tipo
                        de movimeinto lleve valores y la cuenta seleccionada no
                        sea la moneda por defecto.</span
                      >
                    </v-tooltip>
                  </section>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n1">
            <!-- Fecha general -->
            <v-col cols="3" md="3" class="py-0 pr-0">
              <v-menu
                ref="menu-fecha-general"
                v-model="menuFechaGeneral"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaGeneralSelected"
                    label="Fecha"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    outlined
                    dense
                    hint="Formato DD/MM/AAAA"
                    :disabled="canEditAllMovimiento || onlySeeVista"
                    :filled="canEditAllMovimiento || onlySeeVista"
                    v-mask="'##/##/####'"
                    :rules="rules.required.concat(rules.validDate)"
                    @blur="
                      fechaGeneral =
                        helpers.parseDateToIso(fechaGeneralSelected)
                    "
                    @change="
                      fechaGeneral =
                        helpers.parseDateToIso(fechaGeneralSelected)
                    "
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaGeneral"
                  no-title
                  @change="
                    fechaGeneralSelected = helpers.formatDate(fechaGeneral)
                  "
                  @input="menuFechaGeneral = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Fecha Emision -->
            <v-col
              cols="3"
              md="3"
              class="py-0 pl-4 pr-0"
              v-if="
                tipoMovimientoSelect != null
                  ? tipoMovimientoSelect.esEmision
                  : false
              "
            >
              <v-menu
                ref="menu-fecha-emision"
                v-model="menuFechaEmision"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaEmisionSelected"
                    label="Fecha emisión"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    outlined
                    :disabled="canEditAllMovimiento || onlySeeVista"
                    :filled="canEditAllMovimiento || onlySeeVista"
                    dense
                    hint="Formato DD/MM/AAAA"
                    v-mask="'##/##/####'"
                    :rules="rules.required.concat(rules.validDate)"
                    @blur="
                      fechaEmision =
                        helpers.parseDateToIso(fechaEmisionSelected)
                    "
                    @change="
                      fechaEmision =
                        helpers.parseDateToIso(fechaEmisionSelected)
                    "
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaEmision"
                  no-title
                  @change="
                    fechaEmisionSelected = helpers.formatDate(fechaEmision)
                  "
                  @input="menuFechaEmision = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- A orden de -->
            <v-col
              cols="6"
              class="pb-0 py-0 pl-4 pr-0"
              v-if="
                tipoMovimientoSelect != null
                  ? tipoMovimientoSelect.esEmision
                  : false
              "
            >
              <v-text-field
                v-model.trim="aOrdenDe"
                type="text"
                label="A orden de"
                :disabled="canEditAllMovimiento || onlySeeVista"
                :filled="canEditAllMovimiento || onlySeeVista"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <!-- Monedas de consulta -->
            <v-col
              cols="2"
              class="py-0 pr-0"
              md="2"
              v-if="this.paramGralMultimoneda"
            >
              <v-autocomplete
                v-model="monedaSelected"
                :items="monedaItems"
                label="Moneda"
                disabled
                filled
                return-object
                item-text="value"
                item-value="id"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Cotizacion -->
            <v-col
              cols="12"
              md="2"
              class="py-0 pl-4 pr-0"
              v-if="this.paramGralMultimoneda && !this.disableCotiza"
            >
              <currency-input
                dense
                tabindex="16"
                v-model="cotizacion"
                outlined
                :loading="cotizacionLoading"
                @change="changeImporteGralAndCotizacion"
                :options="currencyOptions"
                :rules="rules.required.concat(rules.nonZero)"
                hide-details="auto"
                label="Cotización"
                :disabled="onlySeeVista || canEditAllMovimiento"
                :filled="onlySeeVista || canEditAllMovimiento"
              ></currency-input>
            </v-col>
            <!-- Importe -->
            <v-col cols="12" md="2" class="py-0 pr-0">
              <currency-input
                dense
                tabindex="16"
                v-model="importe"
                outlined
                :rules="rules.required.concat(rules.nonZero)"
                :options="currencyOptions"
                hide-details="auto"
                @change="changeImporteGralAndCotizacion"
                :label="`Importe (${this.monedaSeleccionada})`"
                :disabled="
                  (!this.paramGralMultimoneda && this.disableCotiza) ||
                  canEditAllMovimiento ||
                  onlySeeVista
                "
                :filled="
                  (!this.paramGralMultimoneda && this.disableCotiza) ||
                  canEditAllMovimiento ||
                  onlySeeVista
                "
              ></currency-input>
            </v-col>
            <!-- Importe -->
            <v-col
              v-if="this.paramGralMultimoneda && !this.disableCotiza"
              cols="12"
              :md="
                tipoMovimientoSelect && !tipoMovimientoSelect.esEmision ? 3 : 2
              "
              class="py-0 pl-4 pr-0"
            >
              <currency-input
                dense
                tabindex="16"
                v-model="importeDiferente"
                outlined
                :options="currencyOptions"
                :rules="rules.required.concat(rules.nonZero)"
                hide-details="auto"
                :label="`Importe (${monedaPorDefectoNom})`"
                :disabled="
                  canEditAllMovimiento ||
                  onlySeeVista ||
                  (this.paramGralMultimoneda && !this.disableCotiza)
                "
                :filled="
                  canEditAllMovimiento ||
                  onlySeeVista ||
                  (this.paramGralMultimoneda && !this.disableCotiza)
                "
              ></currency-input>
            </v-col>
            <!-- Estados de conciliacion -->
            <v-col
              cols="12"
              :md="calculateColsNumberEstados"
              class="py-0 pl-4 pr-0"
            >
              <v-autocomplete
                ref="estados-conciliacion"
                v-model="estadoBancarioSelect"
                :items="estadosBancariosItems"
                return-object
                item-text="mbeNombre"
                :rules="rules.required"
                item-value="mbeId"
                outlined
                :disabled="
                  disableConcilia || canEditAllMovimiento || onlySeeVista
                "
                :filled="
                  disableConcilia || canEditAllMovimiento || onlySeeVista
                "
                dense
                autocomplete="off"
                label="Estado"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
            <!-- Fecha conciliacion -->
            <v-col cols="12" md="4" class="py-0 pl-4 pr-0">
              <v-menu
                ref="menu-fecha-concilia"
                v-model="menuFechaConcilia"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaConciliaSelected"
                    label="Fecha conciliación"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    outlined
                    :disabled="disableConcilia || onlySeeVista"
                    :filled="disableConcilia || onlySeeVista"
                    dense
                    hint="Formato DD/MM/AAAA"
                    v-mask="'##/##/####'"
                    :rules="rules.required.concat(rules.validDate)"
                    @blur="
                      fechaConcilia = helpers.parseDateToIso(
                        fechaConciliaSelected
                      )
                    "
                    @change="
                      fechaConcilia = helpers.parseDateToIso(
                        fechaConciliaSelected
                      )
                    "
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaConcilia"
                  no-title
                  @change="
                    fechaConciliaSelected = helpers.formatDate(fechaConcilia)
                  "
                  @input="menuFechaConcilia = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Observaciones -->
            <v-col
              cols="12"
              :md="calculateColsNumberObs"
              class="pb-0 py-0 pr-0"
            >
              <v-text-field
                v-model.trim="observacion"
                type="text"
                label="Observación"
                :disabled="canEditAllMovimiento || onlySeeVista"
                :filled="canEditAllMovimiento || onlySeeVista"
                :rules="[rules.maxLength(observacion, 250)]"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- un cheque por deposito -->
            <v-col
              cols="3"
              class="py-0 my-0 mt-n0"
              v-if="this.showChequePorDeposito && !this.notEnterValor"
            >
              <v-switch
                class="mt-n2"
                v-model="unChquePorDeposito"
                :disabled="
                  this.disableChequePorDeposito ||
                  canEditAllMovimiento ||
                  !this.disableCotiza ||
                  onlySeeVista
                "
                :filled="
                  this.disableChequePorDeposito ||
                  canEditAllMovimiento ||
                  !this.disableCotiza ||
                  onlySeeVista
                "
                label="Un cheque por depósito"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row
            dense
            class="mt-n5"
            v-if="
              tipoMovimientoSelect != null
                ? tipoMovimientoSelect.llevaValores
                : false
            "
          >
            <v-col>
              <v-card class="py-3" outlined v-if="!this.notEnterValor">
                <v-row>
                  <v-col cols="8" class="pb-0 pl-8">
                    <span class="primary--text font-weight-medium"
                      >Valores</span
                    >
                  </v-col>
                  <v-col cols="2" class="ml-n2 text-right" align="end">
                    <v-btn
                      tabindex="23"
                      small
                      :disabled="
                        this.cuentaSelected == undefined ||
                        this.cuentaSelected == null ||
                        canEditAllMovimiento || onlySeeVista
                      "
                      @click="
                        () => {
                          //indicamos que valores se van a excluir de la busqueda
                          if (valoresItems.length > 0) {
                            idsValoresExcluir = valoresItems.map(
                              (val) => val.valId
                            );
                          } else idsValoresExcluir = [];
                          toggleModalValoresCartera();
                        }
                      "
                      color="primary"
                      >Valores en cartera</v-btn
                    >
                  </v-col>
                  <v-col cols="2" class="ml-n2 text-right" align="end">
                    <v-btn
                      tabindex="24"
                      small
                      ref="btnToggleNuevoTipoValor"
                      @click="toggleNuevoTipoValor"
                      :disabled="
                        this.tipoMovimientoSelect == undefined ||
                        this.tipoMovimientoSelect == null ||
                        this.cuentaSelected == undefined ||
                        this.cuentaSelected == null ||
                        canEditAllMovimiento || onlySeeVista
                      "
                      color="primary"
                    >
                      Nuevo valor
                    </v-btn>
                  </v-col>
                </v-row>
                <v-card-text class="py-1">
                  <v-data-table
                    :headers="headersValores"
                    :items="valoresItems"
                    class="elevation-1"
                    no-data-text="No hay valores"
                    dense
                    :hide-default-footer="valoresItems.length <= 10"
                  >
                    <template v-slot:[`item.imp`]="{ item }">
                      <span>
                        {{ helpers.floatToMoneyString(item.imp, "", true) }}
                      </span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            size="20"
                            :disabled="canEditAllMovimiento || onlySeeVista"
                            v-on="on"
                            @click="deleteItemValor(item)"
                          >
                            {{ deleteIcon }}
                          </v-icon>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-row class="mt-n3 mb-n3">
        <v-col class="pl-8 pt-0">
          <v-btn
            color="primary"
            outlined
            @click="toggleHistorialEstados()"
            v-if="onlySeeVista"
          >
            Historial de estados
          </v-btn>
        </v-col>
        <v-col>
          <v-card-actions class="mt-n6 pr-0">
            <v-spacer></v-spacer>
            <v-btn outlined @click="closeModal"> Cancelar </v-btn>
            <v-btn
              type="submit"
              v-if="!onlySeeVista"
              :disabled="
                !isFormValid ||
                canEditAllMovimiento ||
                notEnterValor ||
                onlySeeVista
              "
              form="form"
              color="primary"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
      <!-- MODAL NUEVO TIPO DE VALOR -->
      <v-dialog
        v-if="detalleTipoValorModal"
        v-model="detalleTipoValorModal"
        max-width="60%"
        @keydown.esc="toggleNuevoTipoValor"
        persistent
      >
        <NuevoTipoValor
          @toggleNuevoTipoValor="toggleNuevoTipoValor"
          @addTipoDeValor="addTipoDeValor"
          :empresaSelected="{ id: cuentaSelected.entFacId }"
          :movProvId="0"
          :retencionesItems="valoresItems"
          :tipoValor="tipoValor"
          :fechaSelected="fechaSelected"
          :cotizacion="parseFloat(cotizacion)"
          :monedaOPSelected="monedaSelected"
          :esMovBanc="true"
          :importeSugerido="0"
        ></NuevoTipoValor>
      </v-dialog>
      <!-- MODAL BUSCAR VALORES CARTERA -->
      <v-dialog
        v-model="buscarValoresCarteraModal"
        v-if="buscarValoresCarteraModal"
        @keydown.esc="toggleModalValoresCartera"
        max-width="70%"
        persistent
      >
        <BuscarValoresEnCartera
          :empresaSelectedId="cuentaSelected.entFacId"
          :idsValoresExcluir="idsValoresExcluir"
          @toggleModalValoresCartera="toggleModalValoresCartera"
          @setValoresSelected="setValoresCartera"
        ></BuscarValoresEnCartera>
      </v-dialog>
      <!-- MODAL historial de estados -->
      <v-dialog
        v-if="isOpenHistorialEstados"
        v-model="isOpenHistorialEstados"
        max-width="55%"
        @keydown.esc="toggleHistorialEstados"
        persistent
      >
        <HistorialEstadosMovBanc
          @closeHistorialEstados="toggleHistorialEstados"
          :mbIdToHistorialEstado="editMovimientoItem.mbId"
        ></HistorialEstadosMovBanc>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import helpers from "@/utils/helpers.js";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import NuevoTipoValor from "@/components/modules/proveedores/ordenesPago/NuevoTipoValor.vue";
import BuscarValoresEnCartera from "@/components/modules/proveedores/ordenesPago/BuscarValoresEnCartera.vue";
import HistorialEstadosMovBanc from "@/components/modules/fondos/HistorialEstadosMovBanc.vue";

export default {
  name: "EditMovimientoBancario",
  directives: { mask },
  components: {
    NuevoTipoValor,
    BuscarValoresEnCartera,
    CurrencyInput,
    HistorialEstadosMovBanc,
  },
  props: {
    editMovimientoItem: {
      type: Object,
    },
    onlySeeVista: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    rules: rules,
    helpers: helpers,
    formEditTitle: "Editar movimiento bancario",
    newTitle: "Nuevo movimiento bancario",
    mensajeEditError: "",
    isOpenHistorialEstados: false,
    calendarIcon: enums.icons.CALENDAR,
    infoIcon: enums.icons.SNB_INFO,
    deleteIcon: enums.icons.DELETE,
    seeIcon: enums.icons.SEE,
    estadoBancarioSelect: null,
    estadosBancariosItems: [],
    tipoMovimientoSelect: null,
    tipoMovimientoItems: [],
    monedaItems: [],
    monedaSelected: null,
    monedaSeleccionada: "",
    monedaPorDefecto: null,
    cotizacion: null,
    cotizacionLoading: false,
    importe: null,
    importeDiferente: null,
    aOrdenDe: null,
    numeroComprobante: null,
    observacion: null,
    menuFechaGeneral: null,
    fechaGeneral: null,
    fechaGeneralSelected: null,
    menuFechaEmision: null,
    fechaEmision: null,
    fechaEmisionSelected: null,
    menuFechaConcilia: null,
    fechaConcilia: null,
    fechaConciliaSelected: null,
    monedaPorDefectoNom: "",
    cuentasBanc: [],
    cuentaSelected: null,
    fechaSelected: null,
    banderaCuentaSelected: false,
    cuentaAutocompleteInput: null,
    cuentasLoading: null,
    codCuentas: null,
    tipoValor: "",
    unChquePorDeposito: false,
    loadingBtn: false,
    paramGralMultimoneda: false,
    isFormValid: false,
    detalleTipoValorModal: false,
    disableConcilia: false,
    disableCotiza: true,
    canEditAllMovimiento: false,
    showChequePorDeposito: false,
    disableChequePorDeposito: false,
    notEnterValor: false,
    buscarValoresCarteraModal: false,
    headersValores: [
      { text: "Tipo valor", value: "tvNombre", sortable: false },
      { text: "Banco", sortable: false, value: "bancoNombre" },
      { text: "Cuenta", sortable: false, value: "bctaNombre" },
      { text: "Fecha", sortable: false, value: "fecha" },
      { text: "Número", sortable: false, value: "numero" },
      { text: "Importe", align: "end", sortable: false, value: "imp" },
      { text: "Acciones", align: "end", sortable: false, value: "actions" },
    ],
    valoresItems: [],
    idsValoresExcluir: [],
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0,
        max: 9999999999,
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true,
    },
  }),
  watch: {
    cuentaAutocompleteInput(val) {
      if (val && val.trim().length >= 1) {
        this.getCuentasFilter();
      }
    },
  },
  computed: {
    calculateColsNumberEstados() {
      if (
        this.cuentaSelected &&
        this.cuentaSelected.monedaId != this.monedaPorDefecto.id
      ) {
        if (
          this.tipoMovimientoSelect !== null &&
          this.tipoMovimientoSelect.esEmision
        )
          return 4;
        else return 3;
      } else {
        if (this.tipoMovimientoSelect && this.tipoMovimientoSelect.esEmision)
          return 4;
        else return 5;
      }
    },
    calculateColsNumberObs() {
      if (
        this.cuentaSelected &&
        this.cuentaSelected.monedaId != this.monedaPorDefecto.id
      ) {
        if (
          this.tipoMovimientoSelect !== null &&
          this.tipoMovimientoSelect.esEmision
        )
          return 8;
        else return 5;
      } else {
        if (this.tipoMovimientoSelect && this.tipoMovimientoSelect.esEmision)
          return 12;
        else return 8;
      }
    },
  },
  async mounted() {
    if (this.onlySeeVista) this.formEditTitle = "Vista movimiento bancario";
    await this.setSelects();
    if (this.editMovimientoItem !== undefined) {
      this.disableChequePorDeposito = true;
      this.disableConcilia = true;
      this.newTitle = this.formEditTitle;
      this.setMovBancario(this.editMovimientoItem);
    } else {
      this.estadoBancarioSelect = this.estadosBancariosItems.find(
        (x) => x.mbeCod == "R"
      );
      let fecha = new Date();
      this.fechaGeneral = fecha.toISOString().substring(0, 10);
      this.fechaGeneralSelected = helpers.formatDate(this.fechaGeneral);
    }
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getParamGralMultimoneda: "AdministracionSistema/getParamGralMultimoneda",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getTiposMovBancarios: "fondos/getTiposMovBancarios",
      getTiposMovBancariosHabilitadoUsuario:
        "fondos/getTiposMovBancariosHabilitadoUsuario",
      getEstadosMovBancarios: "fondos/getEstadosMovBancarios",
      getCuentaBancariaByCodigo: "fondos/getCuentaBancariaByCodigo",
      getCotizacionMoneda: "AdministracionSistema/getCotizacionMoneda",
      grabarMovimientoBancario: "fondos/grabarMovimientoBancario",
    }),
    async setMovBancario(val) {
      if (val.mbAsociadoMasValores)
        this.setAlert({
          type: "warning",
          message: "El depósito está asociado con más de un movimiento.",
        });
      if (val.mensajeError != "") {
        this.canEditAllMovimiento = true;
        this.mensajeEditError = val.mensajeError;
      }
      this.tipoMovimientoSelect = this.tipoMovimientoItems.find(
        (x) => x.tmbId == val.tmbId
      );
      this.aOrdenDe = val.aOrdenDe;
      this.estadoBancarioSelect = this.estadosBancariosItems.find(
        (x) => x.mbeNombre == val.estado
      );
      this.observacion = val.observaciones;
      if (val.mbEncaImporte > 0) this.importe = val.mbEncaImporte;
      else this.importe = val.credito == 0 ? val.debito : val.credito;
      if (val.mbEncaNumero != "") this.numeroComprobante = val.mbEncaNumero;
      else this.numeroComprobante = val.numero;
      this.monedaPorDefecto = this.monedaItems.find((x) => x.bool == true);
      this.monedaSelected = this.monedaItems.find((x) => x.id == val.monedaId);
      this.monedaSeleccionada = this.monedaSelected.value;
      if (this.monedaSelected.id != this.monedaPorDefecto.id) {
        this.disableCotiza = false;
        this.importeDiferente = this.importe * val.cotizacion;
      }
      this.cotizacion = val.cotizacion;
      if (val.cuentaBancariaCodigo != "") {
        const res = await this.getCuentaBancariaByCodigo(
          val.cuentaBancariaCodigo
        );
        this.cuentasBanc = res;
        this.cuentaSelected = res[0];
      }
      if (val.fechaMov != "") {
        this.fechaGeneralSelected = val.fechaMov;
        this.fechaGeneral = helpers.parseDateToIso(val.fechaMov);
      }
      if (val.fechaEmision != "") {
        this.fechaEmisionSelected = val.fechaEmision;
        this.fechaEmision = helpers.parseDateToIso(val.fechaEmision);
      }
      if (val.fechaConciliacion != "") {
        this.fechaConciliaSelected = val.fechaConciliacion;
        this.fechaConcilia = helpers.parseDateToIso(val.fechaConciliacion);
      }
      if (val.unDepositoPorCheque) {
        this.showChequePorDeposito = true;
        this.unChquePorDeposito = val.unDepositoPorCheque;
      }
      this.valoresItems = val.listaValores;
    },
    async setSelects() {
      const monedasResponse = await this.getMonedasTaxonomy("habListado");
      this.monedaItems = monedasResponse;
      this.monedaPorDefecto = this.monedaItems.find((x) => x.bool == true);
      this.monedaPorDefectoNom = this.monedaPorDefecto.value;
      const responseParametros = await this.getParamGralMultimoneda();
      this.paramGralMultimoneda = responseParametros[0].multimonedaParams;
      if (this.editMovimientoItem !== undefined) {
        const tiposMovBancarios = await this.getTiposMovBancarios();
        this.tipoMovimientoItems = tiposMovBancarios;
      } else {
        const tiposMovBancarios =
          await this.getTiposMovBancariosHabilitadoUsuario();
        this.tipoMovimientoItems = tiposMovBancarios;
      }
      const estadosBancarios = await this.getEstadosMovBancarios();
      this.estadosBancariosItems = estadosBancarios;
      const today = new Date();
      const dia = String(today.getDate()).padStart(2, "0");
      const mes = String(today.getMonth() + 1).padStart(2, "0");
      const anio = today.getFullYear();
      const fechaHoy = `${dia}/${mes}/${anio}`;
      this.fechaSelected = fechaHoy;
    },
    getCuentasFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.cuentasBanc?.some(
            (x) =>
              x.bctaCodigo == this.cuentaAutocompleteInput ||
              x.bctaNombre == this.cuentaAutocompleteInput
          )
        ) {
          const cuentaCoincidente = this.cuentasBanc.find(
            (x) =>
              x.bctaCodigo === this.cuentaAutocompleteInput ||
              x.bctaNombre === this.cuentaAutocompleteInput
          );

          if (cuentaCoincidente) {
            this.codCuentas = cuentaCoincidente.bctaCodigo;
          }
          return;
        } else if (!this.banderaCuentaSelected) {
          this.cuentasLoading = true;
          try {
            if (this.cuentaAutocompleteInput != "") {
              const res = await this.getCuentaBancariaByCodigo(
                this.cuentaAutocompleteInput
              );
              this.$refs["cuentas-autocomplete"].cachedItems = [];
              this.cuentasBanc = res;
            } else {
              this.cuentasBanc = [];
            }
            this.cuentasLoading = false;
          } catch (error) {
            this.cuentasLoading = false;
          }
        } else {
          this.cuentasLoading = true;
          const response = await this.getCuentaBancariaByCodigo(
            this.cuentaAutocompleteInput
          );
          this.cuentasBanc = response;
          this.cuentaSelected = response[0];
          this.banderaCuentaSelected = false;
          this.cuentasLoading = false;
        }
      }, 1000);
    },
    customFilterCuentas(item, queryText, itemText) {
      return item.bctaNombre || item.bctaCodigo.indexOf(queryText) > -1;
    },
    changeTipoMovimiento() {
      if (
        this.cuentaSelected != null &&
        this.cuentaSelected != undefined &&
        this.tipoMovimientoSelect != null &&
        this.tipoMovimientoSelect != undefined
      ) {
        if (
          this.cuentaSelected.monedaId != this.monedaPorDefecto.id &&
          this.tipoMovimientoSelect.llevaValores
        ) {
          this.notEnterValor = true;
          this.valoresItems = [];
          this.unChquePorDeposito = false;
        } else this.notEnterValor = false;
      } else {
        this.notEnterValor = false;
      }
      if (this.tipoMovimientoSelect != undefined) {
        //se propone fecha de conciliacion y se setea fecha gral
        if (this.fechaGeneral == null) {
          let fecha = new Date();
          this.fechaGeneral = fecha.toISOString().substring(0, 10);
          this.fechaGeneralSelected = helpers.formatDate(this.fechaGeneral);
        }
        if (
          this.tipoMovimientoSelect.diasFechaConcilia == 0 ||
          this.tipoMovimientoSelect.diasFechaConcilia == -1
        ) {
          this.fechaConciliaSelected = this.fechaGeneralSelected;
          this.fechaConcilia = this.fechaGeneral;
        } else if (this.tipoMovimientoSelect.diasFechaConcilia > 0) {
          const fechaEdit = new Date(this.fechaGeneral);
          fechaEdit.setDate(
            fechaEdit.getDate() + this.tipoMovimientoSelect.diasFechaConcilia
          );
          this.fechaConcilia = fechaEdit.toISOString().substring(0, 10);
          this.fechaConciliaSelected = helpers.formatDate(this.fechaConcilia);
        }
        //se propone estado
        if (this.tipoMovimientoSelect.mbeId > 0)
          this.estadoBancarioSelect = this.estadosBancariosItems.find(
            (x) => x.mbeId == this.tipoMovimientoSelect.mbeId
          );
        else
          this.estadoBancarioSelect = this.estadosBancariosItems.find(
            (x) => x.mbeCod == "R"
          );
        //se ve si se muestra el check de un deposito por cheque
        if (
          this.tipoMovimientoSelect.llevaValores &&
          this.tipoMovimientoSelect.signo > 0
        )
          this.showChequePorDeposito = true;
        else {
          this.showChequePorDeposito = false;
          this.unChquePorDeposito = false;
        }
      } else {
        this.showChequePorDeposito = false;
        this.unChquePorDeposito = false;
        this.estadoBancarioSelect = this.estadosBancariosItems.find(
          (x) => x.mbeCod == "R"
        );
        this.fechaGeneral = null;
        this.fechaGeneralSelected = null;
        this.fechaConcilia = null;
        this.fechaConciliaSelected = null;
      }
    },
    async getCotizacion(monedaId) {
      if (monedaId != null) {
        this.importeDiferente = 0.0;
        this.cotizacionLoading = true;
        const payload = {
          monedaId: monedaId.id,
          fechaComprobante: this.fechaGeneral,
        };
        try {
          const response = await this.getCotizacionMoneda(payload);
          if (response) this.cotizacion = response;
          else this.cotizacion = null;
        } catch (error) {
        } finally {
          this.cotizacionLoading = false;
        }
      }
    },
    async changeCuentaSelect() {
      if (
        this.tipoMovimientoSelect != null &&
        this.tipoMovimientoSelect != undefined &&
        this.cuentaSelected != null &&
        this.cuentaSelected != undefined
      ) {
        if (
          this.tipoMovimientoSelect.llevaValores &&
          this.cuentaSelected.monedaId != this.monedaPorDefecto.id
        ) {
          this.notEnterValor = true;
          this.valoresItems = [];
          this.unChquePorDeposito = false;
        } else this.notEnterValor = false;
      } else {
        this.notEnterValor = false;
      }
      if (this.editMovimientoItem != undefined)
        this.disableChequePorDeposito = false;
      if (this.cuentaSelected != null) {
        let moneda = this.monedaItems.find(
          (x) => x.id == this.cuentaSelected.monedaId
        );
        this.monedaSelected = moneda;
        this.monedaSeleccionada = moneda.value;
        if (this.monedaSelected.bool) this.disableCotiza = true;
        else this.disableCotiza = false;
        this.unChquePorDeposito = this.cuentaSelected.unDepositoPorCheque;
      } else {
        this.monedaSeleccionada = "";
        this.importe = null;
        this.unChquePorDeposito = false;
        this.monedaSelected = null;
      }

      if (this.cuentaSelected.monedaId != this.monedaPorDefecto.id)
        await this.getCotizacion(this.monedaSelected);
    },
    changeImporteGralAndCotizacion() {
      if (!this.disableCotiza && this.cotizacion > 0 && this.importe > 0) {
        this.importeDiferente = this.importe * this.cotizacion;
      } else {
        this.importeDiferente = null;
      }
    },
    async canSave() {
      if (this.importe == 0) {
        this.setAlert({
          type: "error",
          message: "Debe ingresar un importe mayor a 0.",
        });
      } else {
        this.isFormValid = false;
        let arrayValores = [];
        this.valoresItems.forEach((x) => {
          arrayValores.push({
            MbId: x.mbId == undefined ? 0 : x.mbId,
            ValId: x.valId == undefined ? 0 : x.valId,
            TvId: x.tvId,
            Numero: x.numero,
            Fecha:
              x.fecha != null
                ? new Date(x.fecha.split("/").reverse().join("-"))
                : null,
            FechaEmision: null,
            BancoId: x.bancoId,
            PersonaId: 0,
            PersonaNombre: x.aOrdenDe,
            PersonaTabla: null,
            Imp: x.imp,
            ImpComp: x.importeComp,
          });
        });
        let mbEncaToSave = 0;
        if (this.editMovimientoItem != undefined) {
          if (this.unChquePorDeposito) {
            mbEncaToSave = this.editMovimientoItem.mbEncaId;
          }
        }
        const data = {
          MbId:
            this.editMovimientoItem != undefined
              ? this.editMovimientoItem.mbId
              : 0,
          MbFecha: this.fechaGeneral,
          EntFacId: this.cuentaSelected.entFacId,
          FechaEmision: this.fechaEmision,
          MbNumero: this.numeroComprobante,
          BCtaId: this.cuentaSelected.bctaId,
          Observaciones: this.observacion == null ? "" : this.observacion,
          MbSigno: this.tipoMovimientoSelect.signo,
          Importe: this.monedaSelected.bool
            ? this.importe
            : this.importe * this.cotizacion,
          AOrdenDe: this.aOrdenDe == null ? "" : this.aOrdenDe,
          MbeId: this.estadoBancarioSelect.mbeId,
          MbEncaId: mbEncaToSave,
          UnChequePorDeposito: this.monedaSelected.bool
            ? this.unChquePorDeposito
            : false,
          FechaConcilia: this.fechaConcilia,
          MonedaId: this.monedaPorDefecto.id,
          MonedaIdComp: this.monedaSelected.bool
            ? null
            : this.monedaSelected.id,
          Cotizacion: this.cotizacion,
          ImporteComp: this.monedaSelected.bool ? null : this.importe,
          TmbId: this.tipoMovimientoSelect.tmbId,
          BancoId: this.cuentaSelected.bancoId,
          SesionId: "",
          ListaValores: arrayValores,
        };
        try {
          const response = await this.grabarMovimientoBancario(data);
          if (response.idError == 0) {
            this.setAlert({ type: "success", message: "Guardado con éxito." });
            this.closeModal();
            this.isFormValid = true;
          } else {
            const mensajeError = response.mensaje;
            this.setAlert({ type: "error", message: mensajeError });
            this.closeModal();
            this.isFormValid = true;
          }
        } catch (error) {
          this.isFormValid = true;
        }
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    toggleNuevoTipoValor() {
      this.detalleTipoValorModal = !this.detalleTipoValorModal;
    },
    toggleModalValoresCartera() {
      this.buscarValoresCarteraModal = !this.buscarValoresCarteraModal;
    },
    deleteItemValor(item) {
      this.valoresItems = this.valoresItems.filter((x) => x !== item);
      const sumaImportes = this.valoresItems.reduce((acumu, item) => {
        return acumu + (item.imp || 0);
      }, 0);
      this.importe = sumaImportes;
    },
    setValoresCartera(itemsSelected) {
      const newArray = itemsSelected.map((item) => ({
        bancoId: item.bancoId,
        bancoNombre: item.bancoNombre,
        compDestino: item.compDestino,
        compOrigen: item.compOrigen,
        destino: item.destino,
        empresa: item.empresa,
        fecAlta: item.fecAlta,
        fecModi: item.fecModi,
        fecha: item.vencimiento,
        imp: item.imp,
        impToShow: item.impToShow,
        numero: item.numero,
        observaciones: item.observaciones,
        origen: item.origen,
        sucursal: item.sucursal,
        tvId: item.tvId,
        tvNombre: item.tvNombre,
        usuAlta: item.usuAlta,
        usuModi: item.usuModi,
        valId: item.valId
      }))
      this.valoresItems.push(...newArray);
      this.importe = 0;
      const sumaImportes = this.valoresItems.reduce((acumu, item) => {
        return acumu + (item.imp || 0);
      }, 0);
      this.importe = sumaImportes;
      //Se valida la fecha
      let fechaMayor = itemsSelected.some(
        (item) => {
          const [dia, mes, anio] = item.vencimiento.split("/").map(Number);
          const fechaItem = new Date(anio, mes - 1, dia);
          const fechaGeneral = new Date(this.fechaGeneral + "T00:00:00");
          fechaItem.setHours(0, 0, 0, 0);
          fechaGeneral.setHours(0, 0, 0, 0);
          return fechaItem > fechaGeneral;
        }
      );
      if (fechaMayor)
        this.setAlert({
          type: "warning",
          message:
            "ATENCIÓN: La fecha del valor es mayor a la fecha del movimiento. ",
        });
    },
    addTipoDeValor(detalle) {
      if (this.monedaSelected.id == this.monedaPorDefecto.id) {
        if (detalle.imp == 0) {
          this.setAlert({
            type: "error",
            message: "Debe ingresar valores mayores a cero.",
          });
        } else {
          this.valoresItems.push(detalle);
          this.importe = 0;
          const sumaImportes = this.valoresItems.reduce((acumu, item) => {
            return acumu + (item.imp || 0);
          }, 0);
          this.importe = sumaImportes;
        }
      } else {
        if (detalle.importeComp == 0) {
          this.setAlert({
            type: "error",
            message: "Debe ingresar valores mayores a cero.",
          });
        } else {
          detalle.imp = detalle.importeComp;
          this.valoresItems.push(detalle);
          this.importe = (this.importe || 0) + (detalle.importeComp || 0);
        }
      }
      //Convierto ambas fechas para poder comparar y validar
      const [dia, mes, anio] = detalle.fecha.split("/").map(Number);
      const fechaDetalle = new Date(anio, mes - 1, dia);
      const fechaGeneral = new Date(this.fechaGeneral + "T00:00:00");
      fechaDetalle.setHours(0, 0, 0, 0);
      fechaGeneral.setHours(0, 0, 0, 0);
      //Se valida la fecha
      if (fechaDetalle > fechaGeneral)
        this.setAlert({
          type: "warning",
          message:
            "ATENCIÓN: La fecha del valor es mayor a la fecha del movimiento. ",
        });
    },
    toggleHistorialEstados() {
      this.isOpenHistorialEstados = !this.isOpenHistorialEstados;
    },
  },
};
</script>

