<template>
  <v-card>
    <v-container>
      <v-card-title class="mt-n3">
        <span class="primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="canSave()"
        >
          <v-row class="mt-n6">
            <!-- Fecha -->
            <v-col cols="12" md="6">
              <v-menu
                ref="vigencia-desde"
                v-model="menuFechaMov"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaMovSelected"
                    label="Fecha"
                    :disabled="noModificarFecha"
                    :filled="noModificarFecha"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaMov = helpers.parseDateToIso(fechaMovSelected)"
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    v-on="on"
                    :rules="
                      noModificarFecha
                        ? []
                        : rules.required.concat(rules.validDate)
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaMov"
                  no-title
                  @change="fechaMovSelected = helpers.formatDate(fechaMov)"
                  @input="menuFechaMov = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Estados de conciliacion -->
            <v-col cols="12" md="6">
              <v-autocomplete
                ref="estados-conciliacion"
                v-model="estadoBancarioSelect"
                :items="estadosBancariosItems"
                return-object
                item-text="mbeNombre"
                :rules="rules.required"
                item-value="mbeId"
                outlined
                dense
                autocomplete="off"
                label="Estado"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- Modificar fecha -->
            <v-col cols="12" class="py-0 my-0 mt-n8">
              <v-switch
                v-model="noModificarFecha"
                class="mt-1"
                @change="changeNoModificarFecha"
                label="No modificar fecha de conciliación"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mt-n11">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import helpers from "@/utils/helpers.js";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
export default {
  name: "EditEstadoMovBanc",
  directives: { mask },
  props: {
    comprobantesToEditEstados: {
      type: Array,
    },
  },
  data: () => ({
    rules: rules,
    helpers: helpers,
    formEditTitle: "",
    calendarIcon: enums.icons.CALENDAR,
    menuFechaMov: false,
    fechaMovSelected: null,
    fechaMov: null,
    estadoBancarioSelect: null,
    estadosBancariosItems: [],
    isFormValid: false,
    noModificarFecha: false,
  }),
  created() {
    this.setSelects();
    this.setPantalla();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getEstadosMovBancarios: "fondos/getEstadosMovBancarios",
      saveEstadosConciliacionMovBanc: "fondos/saveEstadosConciliacionMovBanc",
    }),
    async setSelects() {
      const estadosBancarios = await this.getEstadosMovBancarios();
      this.estadosBancariosItems = estadosBancarios;
    },
    async setPantalla() {
      if (this.comprobantesToEditEstados.length > 1) {
        this.formEditTitle = "Editar estados";
      } else {
        this.formEditTitle = "Editar estado";
      }
      let fecha = new Date();
      this.fechaMov = fecha.toISOString().substring(0, 10);
      this.fechaMovSelected = this.formatDate(this.fechaMov);
    },
    changeNoModificarFecha() {
      if (this.noModificarFecha) {
        this.fechaMov = null;
        this.fechaMovSelected = null;
      } else {
        let fecha = new Date();
        this.fechaMov = fecha.toISOString().substring(0, 10);
        this.fechaMovSelected = this.formatDate(this.fechaMov);
      }
    },
    async canSave() {
      this.isFormValid = false;
      //se chequea que la fecha del mov sea menor a la fecha concilia
      let fechaMenor = true;
      if (this.fechaMov != null) {
        const [anioSel, mesSel, diaSel] = this.fechaMov.split("-").map(Number);
        const fechaSeleccionada = new Date(anioSel, mesSel - 1, diaSel);
        for (const obj of this.comprobantesToEditEstados) {
          const [dia, mes, anio] = obj.fechaMov.split("/").map(Number);
          const fechaMovimiento = new Date(anio, mes - 1, dia);
          fechaMovimiento.setHours(0, 0, 0, 0);
          if (fechaSeleccionada < fechaMovimiento) {
            fechaMenor = false;
            break;
          }
        }
      }
      if (fechaMenor) {
        const arrayIds = this.comprobantesToEditEstados.map((x) => x.mbId);
        const data = {
          MbIds: arrayIds,
          MbeId: this.estadoBancarioSelect.mbeId,
          FechaConcilia: this.fechaMov,
        };
        try {
          const response = await this.saveEstadosConciliacionMovBanc(data);
          if (response) {
            this.setAlert({ type: "success", message: "Guardado con éxito." });
            this.closeModal();
            this.isFormValid = true;
          }
        } catch (error) {
          this.isFormValid = true;
        }
      } else {
        this.setAlert({
          type: "error",
          message:
            "La fecha seleccionada no puede ser menor a la fecha del movimiento.",
        });
        this.isFormValid = true;
      }
    },
    closeModal() {
      this.$emit("closeAndReloadMovimientos");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
  },
};
</script>

<style scoped>
</style>