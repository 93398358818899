<template>
  <div>
    <v-card>
      <v-card-title class="ml-0 pt-2">
        <span class="primary--text">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-card class="elevation-2" outlined>
          <v-row class="px-2">
            <v-col
              cols="12"
              md="10"
              class="py-0"
              align="left"
              @click="showFilters = true"
            >
              <FiltersSelected :filters="filtersApplied" v-if="!showFilters" />
            </v-col>
            <v-col
              cols="12"
              md="2"
              align="right"
              align-self="center"
              class="pt-0"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="primary--text"
                    @click.stop="showFilters = !showFilters"
                  >
                    {{ closeFiltersIcon }}
                  </v-icon>
                </template>
                <span>{{
                  !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-expand-transition>
            <v-form
              v-show="showFilters"
              v-model="isFormValid"
              ref="filters-form-busqueda-valores"
              id="filters-form-busqueda-valores"
              @submit.prevent="applyFilters()"
            >
              <v-row class="mb-n6">
                <!-- tipo de fecha -->
                <v-col cols="3" class="p-0 pl-6 pb-1">
                  <v-autocomplete
                    v-model="tipoFechaSelected"
                    :items="itemsTiposFecha"
                    return-object
                    label="Tipo de fecha"
                    item-text="value"
                    item-value="id"
                    outlined
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <!-- fecha desde -->
                <v-col cols="12" sm="2" md="2" class="p-0 pl-1 pb-1">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    hide-details="auto"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fechaDesdeSelected"
                        label="Fecha desde"
                        :append-icon="calendarIcon"
                        v-bind="attrs"
                        outlined
                        dense
                        hint="Formato DD/MM/AAAA"
                        v-mask="'##/##/####'"
                        onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                        @blur="
                          fechaDesde =
                            helpers.parseDateToIso(fechaDesdeSelected)
                        "
                        v-on="on"
                        :rules="
                          rules.required.concat(
                            rules.validDate,
                            new Date(
                              helpers.parseDateToIso(fechaDesdeSelected)
                            ) <=
                              new Date(
                                helpers.parseDateToIso(fechaHastaSelected)
                              ) || 'Formato incorrecto'
                          )
                        "
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fechaDesde"
                      no-title
                      @change="
                        fechaDesdeSelected = helpers.formatDate(fechaDesde)
                      "
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- fecha hasta -->
                <v-col cols="12" sm="2" md="2" class="pt-0 pl-1 pr-1">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    hide-details="auto"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fechaHastaSelected"
                        label="Fecha hasta"
                        :append-icon="calendarIcon"
                        v-bind="attrs"
                        outlined
                        hide-details="auto"
                        dense
                        hint="Formato DD/MM/AAAA"
                        v-mask="'##/##/####'"
                        onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                        @blur="
                          fechaHasta =
                            helpers.parseDateToIso(fechaHastaSelected)
                        "
                        v-on="on"
                        :rules="
                          rules.required.concat(
                            rules.validDate,
                            new Date(
                              helpers.parseDateToIso(fechaDesdeSelected)
                            ) <=
                              new Date(
                                helpers.parseDateToIso(fechaHastaSelected)
                              ) || 'Formato incorrecto'
                          )
                        "
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fechaHasta"
                      no-title
                      @change="
                        fechaHastaSelected = helpers.formatDate(fechaHasta)
                      "
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- Empresa -->
                <v-col cols="12" sm="2" md="2" class="py-0 p-0 pl-n3">
                  <v-autocomplete
                    v-model="empresaSelected"
                    :items="empresasItems"
                    item-text="value"
                    item-value="id"
                    return-object
                    outlined
                    disabled
                    filled
                    readonly
                    dense
                    clearable
                    label="Entidad facturante"
                  ></v-autocomplete>
                </v-col>
                <!-- Tipo valor -->
                <v-col cols="12" sm="3" md="3" class="py-0 pl-1 pr-8">
                  <v-autocomplete
                    v-model="tipoValorSelected"
                    :items="tipoValorItems"
                    item-text="tvNombre"
                    item-value="tvId"
                    return-object
                    outlined
                    dense
                    clearable
                    label="Tipo valor"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="py-0 px-2">
                <!-- Bancos -->
                <v-col cols="12" sm="3" md="3" class="p-0 pl-4 pr-1 pb-1">
                  <v-autocomplete
                    v-model="bancosSelected"
                    :items="bancosItems"
                    item-text="value"
                    item-value="id"
                    return-object
                    outlined
                    dense
                    clearable
                    label="Bancos"
                  ></v-autocomplete>
                </v-col>
                <!-- Origen -->
                <v-col cols="12" sm="3" md="3" class="p-0 pl-0 pb-1 pr-4">
                  <v-autocomplete
                    v-model="origenSelected"
                    :items="origenItems"
                    item-text="nombre"
                    item-value="codigo"
                    return-object
                    outlined
                    dense
                    clearable
                    label="Origen"
                  ></v-autocomplete>
                </v-col>
                <!-- Destino -->
                <v-col cols="12" sm="3" md="3" class="py-0 ml-n6 pr-0">
                  <v-autocomplete
                    v-model="destinoSelected"
                    :items="destinoItems"
                    item-text="nombre"
                    item-value="codigo"
                    return-object
                    outlined
                    dense
                    clearable
                    label="Destino"
                  ></v-autocomplete>
                </v-col>
                <!-- numero -->
                <v-col cols="12" md="3" sm="3" class="py-0 pl-1 pr-0">
                  <v-text-field
                    type="text"
                    class="center-input"
                    v-model="numero"
                    outlined
                    dense
                    persistent-hint
                    v-mask="'############'"
                    label="Numero"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-n6 mb-2">
                <!-- Importe -->
                <v-col cols="3" class="py-0 pl-6">
                  <currency-input
                    v-model="importe"
                    label="Importe"
                    :options="currencyOptions"
                    :rules="[]"
                  ></currency-input>
                </v-col>
                <v-col
                  cols="9"
                  align="end"
                  class="text-right to-right pr-8 pt-3 pb-0"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    elevation="2"
                    small
                    :disabled="!isFormValid"
                    type="submit"
                    form="filters-form-busqueda-valores"
                  >
                    Aplicar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-expand-transition>
        </v-card>
        <v-data-table
          :headers="headersValores"
          :items="itemsValores"
          v-model="valoresEnCarteraSelected"
          height="100%"
          show-expand
          show-select
          :expanded.sync="expanded"
          class="elevation-3 mt-7"
          @toggle-select-all="selectAllToggle"
          :loading="isLoadingTable"
          @click:row="selectWhenClickRow($event)"
          item-key="valId"
        >
          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox
              :value="isSelected"
              color="primary"
              @input="select($event)"
              :ripple="false"
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" size="20" v-on="on">
                  {{ seeIcon }}
                </v-icon>
              </template>
              <div>Sucursal: {{ item.sucursal }}</div>
              <div>Origen: {{ item.origen }}</div>
              <div>Comprobante origen: {{ item.compOrigen }}</div>
              <div>Destino: {{ item.destino }}</div>
              <div>Comprobante destino: {{ item.compDestino }}</div>
            </v-tooltip>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click.stop="handleExpansion(item, isExpanded)"
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  >{{
                    isExpanded ? "mdi-account-check" : "mdi-account"
                  }}</v-icon
                >
              </template>
              <span>Usuarios</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Fecha alta:</strong>
              {{ item.fecAlta ? item.fecAlta : "Sin datos" }}
              <strong style="padding-left: 60px">Usuario alta:</strong>
              {{ item.usuAlta ? item.usuAlta : "Sin datos" }}
              <strong style="padding-left: 60px">Fecha modificación:</strong>
              {{ item.fecModi ? item.fecModi : "Sin datos" }}
              <strong style="padding-left: 60px">Usuario modificación:</strong>
              {{ item.usuModi ? item.usuModi : "Sin datos" }}
              <strong style="padding-left: 40px">Observación:</strong>
              {{ item.observaciones ? item.observaciones : "Sin datos" }}
            </td>
          </template>
        </v-data-table>
        <v-card-actions class="pt-4 mr-n2 pb-0">
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeModal"> Cancelar </v-btn>
          <v-btn
            :disabled="valoresEnCarteraSelected.length === 0"
            form="form"
            color="primary"
            @click="setValoresSelected()"
          >
            Seleccionar
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import helpers from "@/utils/helpers.js";
import { mask } from "vue-the-mask";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

export default {
  name: "BuscarValoresEnCartera",
  directives: { mask },
  components: {
    FiltersSelected,
    CurrencyInput,
  },
  data() {
    return {
      rules: rules,
      helpers: helpers,
      title: "Búsqueda de valores en cartera",
      isFormValid: false,
      showFilters: true,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      chevronUpIcon: enums.icons.CHEVRON_UP,
      seeIcon: enums.icons.SEE,
      chevronDownIcon: enums.icons.CHEVRON_DOWN,
      calendarIcon: enums.icons.CALENDAR,
      isLoadingTable: false,
      currencyOptions: {
        locale: "nl-NL",
        currency: "USD",
        currencyDisplay: "narrowSymbol",
        valueRange: {
          min: 0.0,
          max: 9999999999.99,
        },
        precision: 2,
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        exportValueAsInteger: false,
        autoSign: true,
        useGrouping: true,
      },
      valoresEnCarteraSelected: [],
      filtersApplied: [],
      //filtros
      tipoFechaSelected: { id: "R", value: "Fecha recepción" },
      itemsTiposFecha: [
        { id: "R", value: "Fecha recepción" },

        { id: "V", value: "Fecha de vencimiento" },
      ],
      fechaDesde: new Date(new Date().setMonth(new Date().getMonth() - 6))
        .toISOString()
        .substr(0, 10),

      fechaDesdeSelected: helpers.formatDate(
        new Date(new Date().setMonth(new Date().getMonth() - 6))
          .toISOString()
          .substr(0, 10)
      ),
      menu1: null,
      fechaHastaSelected: helpers.formatDate(
        new Date(new Date()).toISOString().substr(0, 10)
      ),
      fechaHasta: new Date().toISOString().substr(0, 10),
      menu2: null,
      empresasItems: [],
      empresaSelected: null,
      soloEnCartera: true,
      importe: null,
      numero: null,
      destinoItems: [],
      destinoSelected: null,
      origenItems: [],
      origenSelected: null,
      bancosItems: [],
      bancosSelected: null,
      tipoValorItems: [],
      tipoValorSelected: null,
      itemsValores: [],
      expanded: [],
      headersValores: [
        {
          text: "Fecha",
          sortable: false,
          align: "left",
          value: "fecha",
        },
        {
          text: "Vencimiento",
          align: "left",
          sortable: false,
          value: "vencimiento",
        },
        {
          text: "Tipo valor",
          sortable: false,
          align: "left",
          value: "tvNombre",
        },
        {
          text: "Importe",
          sortable: false,
          align: "right",
          value: "impToShow",
        },
        {
          text: "Número",
          sortable: false,
          align: "right",
          value: "numero",
        },
        {
          text: "Banco",
          sortable: false,
          align: "left",
          value: "bancoNombre",
        },
        {
          text: "Empresa",
          sortable: false,
          align: "left",
          value: "empresa",
        },
        { text: "Ver", align: "end", sortable: false, value: "actions" },
        { text: "", align: "end", value: "data-table-expand" },
      ],
    };
  },
  props: {
    empresaSelectedId: {
      type: Number,
      default: {},
    },
    idsValoresExcluir: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  async created() {
    await this.setSelects();
    this.applyFilters();
  },
  methods: {
    ...mapActions({
      getParamGralMultimoneda: "AdministracionSistema/getParamGralMultimoneda",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      getTiposValoresChequeTerceros:
        "proveedores/getTiposValoresChequeTerceros",
      getOrigenDestino: "proveedores/getOrigenDestino",
      getBancos: "proveedores/getBancos",
      getValoresByFilters: "proveedores/getValoresByFilters",
      setAlert: "user/setAlert",
    }),
    async setSelects() {
      const entidadesFacturantes = await this.getEntidadesFacturantes();
      this.empresasItems = entidadesFacturantes;
      this.empresaSelected = this.empresasItems.find(
        (x) => x.id === this.empresaSelectedId
      );
      const tiposValores = await this.getTiposValoresChequeTerceros();
      this.tipoValorItems = tiposValores;
      const origenDestino = await this.getOrigenDestino();
      this.origenItems = origenDestino;
      this.destinoItems = origenDestino;
      const bancos = await this.getBancos();
      this.bancosItems = bancos;
    },
    async applyFilters() {
      this.customizeFiltersApplied();
      this.showFilters = false;
      try {
        this.isLoadingTable = true;
        const data = {
          fecDesde: this.fechaDesde,
          fecHasta: this.fechaHasta,
          tvId: this.tipoValorSelected ? this.tipoValorSelected.tvId : 0,
          entFacId: this.empresaSelected ? this.empresaSelected.id : 0,
          moduloOrigen: this.origenSelected ? this.origenSelected.codigo : "",
          moduloDestino: this.destinoSelected
            ? this.destinoSelected.codigo
            : "",
          bancoId: this.bancosSelected ? this.bancosSelected.id : 0,
          cartera: true,
          importe: this.importe ? parseFloat(this.importe) : 0,
          numero: this.numero ? this.numero : "",
          tipoFecha: this.tipoFechaSelected.id,
          fechaCartera: new Date().toISOString().substr(0, 10),
          eliminarEntregados: false,
        };
        const response = await this.getValoresByFilters(data);
        if (response) {
          this.itemsValores = response;
          this.itemsValores = this.itemsValores.filter(
            (item) => !this.idsValoresExcluir.includes(item.valId)
          );
        }
      } catch {
      } finally {
        this.isLoadingTable = false;
      }
    },
    selectAllToggle() {
      if (this.valoresEnCarteraSelected.length != this.itemsValores.length) {
        this.valoresEnCarteraSelected = this.itemsValores;
        this.setAlert({
          type: "info",
          message: "Todos los valores han sido seleccionados.",
        });
      } else {
        this.valoresEnCarteraSelected = [];
        this.setAlert({
          type: "info",
          message: "Todos los valores han sido deseleccionados.",
        });
      }
    },
    setValoresSelected() {
      this.$emit("setValoresSelected", this.valoresEnCarteraSelected);
      this.closeModal();
    },
    formatDecimalMoney(valor) {
      const formatter = new Intl.NumberFormat("es-ES", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(valor);
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.tipoFechaSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "tipoFechaSelected",
          label: "Tipo de fecha",
          model: this.tipoFechaSelected.value,
        });
      }
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "fechaDesdeSelected",
          label: "Fecha desde",
          model: this.fechaDesdeSelected,
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(3, 1, {
          key: "fechaHastaSelected",
          label: "Fecha hasta",
          model: this.fechaHastaSelected,
        });
      }
      if (this.tipoValorSelected) {
        this.filtersApplied.splice(5, 1, {
          key: "tipValorSelected",
          label: "Tipo valor",
          model: this.tipoValorSelected.tvNombre,
        });
      }
      if (this.empresaSelected) {
        this.filtersApplied.splice(7, 1, {
          key: "empresaSelected",
          label: "Entidad facturante",
          model: this.empresaSelected.value,
        });
      }
      if (this.bancosSelected) {
        this.filtersApplied.splice(5, 1, {
          key: "bancoSelected",
          label: "Banco",
          model: this.bancosSelected.value,
        });
      }
      if (this.origenSelected) {
        this.filtersApplied.splice(5, 1, {
          key: "origenSelected",
          label: "Origen",
          model: this.origenSelected.nombre,
        });
      }
      if (this.destinoSelected) {
        this.filtersApplied.splice(5, 1, {
          key: "destinoSelected",
          label: "Destino",
          model: this.destinoSelected.nombre,
        });
      }
      if (this.numero) {
        this.filtersApplied.splice(6, 1, {
          key: "numero",
          label: "Número",
          model: this.numero,
        });
      }
      if (this.importe) {
        this.filtersApplied.splice(7, 1, {
          key: "importe",
          label: "Importe",
          model: this.formatDecimalMoney(this.importe),
        });
      }
      if (this.soloEnCartera) {
        this.filtersApplied.splice(10, 1, {
          key: "soloEnCartera",
          label: `Solo en cartera`,
          model: this.soloEnCartera ? "SI" : "",
        });
      }
    },
    selectWhenClickRow(item) {
      const isSelected = this.valoresEnCarteraSelected.some(
        (selectedItem) => selectedItem.valId === item.valId
      );
      if (!isSelected) this.valoresEnCarteraSelected.push(item);
      else
        this.valoresEnCarteraSelected = this.valoresEnCarteraSelected.filter(
          (x) => x !== item
        );
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    closeModal() {
      this.itemsValores = [];
      this.$emit("toggleModalValoresCartera");
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
  },
};
</script> 
