<template>
  <v-container class="p-0">
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <v-row class="mb-n3">
          <v-col cols="12" md="10" class="mt-5">
            <PageHeader :title="title" />
          </v-col>
        </v-row>
        <v-card class="mx-auto mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row>
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="pt-1"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row class="mt-n2">
                  <!-- Tipo de fecha -->
                  <v-col cols="3" md="3" class="py-0 pr-0">
                    <v-autocomplete
                      v-model="tipoFechaSelected"
                      :items="tiposFechasItems"
                      return-object
                      item-text="value"
                      item-value="id"
                      :rules="rules.required"
                      outlined
                      dense
                      autocomplete="off"
                      label="Tipo de fecha"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Fecha desde -->
                  <v-col cols="2" md="2" sm="6" class="py-0 pl-2 pr-0">
                    <v-menu
                      ref="menu-fecha-desde"
                      v-model="menuFechaDesde"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          @keyup.enter="isFormValid && applyFilters"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          @change="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menuFechaDesde = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Fecha hasta -->
                  <v-col cols="2" md="2" sm="6" class="py-0 pr-0 pl-2">
                    <v-menu
                      ref="menu-fecha-hasta"
                      v-model="menuFechaHasta"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          @keyup.enter="isFormValid && applyFilters"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          @change="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menuFechaHasta = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Cuentas bancarias -->
                  <v-col cols="5" class="py-0 pl-2">
                    <v-autocomplete
                      class="mb-0 mt-0 pt-0"
                      outlined
                      clearable
                      dense
                      multiple
                      v-model="cuentaBancariaSelect"
                      @change="changeCuentaBancaria"
                      label="Cuentas bancarias"
                      :rules="rules.required"
                      item-text="bctaNombre"
                      item-value="bctaId"
                      hint="Búsqueda por nombre de cuenta"
                      persistent-hint
                      autocomplete="on"
                      :items="cuentaBancariaItems"
                      return-object
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.bctaNombre }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text caption">
                          (+{{ cuentaBancariaSelect.length - 1 }} otros)
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="mt-n1">
                  <!-- tipos de movimientos -->
                  <v-col cols="4" class="py-0 pl-3 pr-0">
                    <v-autocomplete
                      class="mb-0 mt-0 pt-0"
                      outlined
                      clearable
                      dense
                      multiple
                      v-model="tipoMovimientoSelect"
                      label="Tipo de movimiento"
                      item-text="tmbNombre"
                      item-value="tmbId"
                      autocomplete="on"
                      :items="tipoMovimientoItems"
                      return-object
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="tipoMovimientoToggle()">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                tipoMovimientoSelect.length > 0 ? 'primary' : ''
                              "
                            >
                              {{ multiselectIconTipoMov }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Todos </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.tmbNombre }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text caption">
                          (+{{ tipoMovimientoSelect.length - 1 }} otros)
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- numero de comp desde -->
                  <v-col cols="2" class="pb-0 py-0 pl-2 pr-0">
                    <v-text-field
                      v-model.trim="numeroDeCompDesde"
                      type="text"
                      label="Número desde"
                      :rules="
                        rules.nonZeroNotRequired.concat([
                          rules.validDateRangeNumber(
                            numeroDeCompDesde,
                            numeroDeCompHasta
                          ),
                        ])
                      "
                      dense
                      outlined
                      v-mask="'#############'"
                    ></v-text-field>
                  </v-col>
                  <!-- numero de comp hasta -->
                  <v-col cols="2" class="pb-0 py-0 pr-0 pl-2">
                    <v-text-field
                      v-model.trim="numeroDeCompHasta"
                      type="text"
                      label="Número hasta"
                      :rules="
                        rules.nonZeroNotRequired.concat([
                          rules.validDateRangeNumber(
                            numeroDeCompDesde,
                            numeroDeCompHasta
                          ),
                        ])
                      "
                      dense
                      outlined
                      v-mask="'#############'"
                    ></v-text-field>
                  </v-col>
                  <!-- Estados de mov bancarios -->
                  <v-col cols="4" class="py-0 pl-2">
                    <v-autocomplete
                      class="mb-0 mt-0 pt-0"
                      outlined
                      clearable
                      dense
                      multiple
                      v-model="estadoBancarioSelect"
                      label="Estados"
                      item-text="mbeNombre"
                      item-value="mbeId"
                      autocomplete="on"
                      :items="estadosBancariosItems"
                      return-object
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="estadoBancarioToggle()">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                estadoBancarioSelect.length > 0 ? 'primary' : ''
                              "
                            >
                              {{ multiselectIconEstadoBancario }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Todos </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.mbeNombre }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text caption">
                          (+{{ estadoBancarioSelect.length - 1 }} otros)
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row justify="end" class="mt-n7">
                  <v-col cols="12" md="4" sm="6" class="pb-0 pt-5 text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="!isFormValid"
                      elevation="2"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <!-- Tabla gral de mov. bancarios -->
          <v-data-table
            :headers="headersMovBancarios"
            :items="movBancariosItems"
            class="elevation-1"
            :search="search"
            :loading="isLoadingTable"
            item-key="mbId"
            show-expand
            v-model="registrosSeleccionados"
            :expanded.sync="expanded"
            @toggle-select-all="selectAllToggle"
            :show-select="movBancariosItems.length > 0"
          >
            <!-- seleccion de movimiento -->
            <template
              v-slot:[`item.data-table-select`]="{ isSelected, select }"
            >
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-simple-checkbox
                    :value="isSelected"
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    @input="select($event)"
                    :ripple="false"
                  ></v-simple-checkbox>
                </template>
                <span>Seleccionar movimiento</span>
              </v-tooltip>
            </template>
            <!-- Expansion de usuario -->
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.credito`]="{ item }">
              <span>{{ helpers.floatToMoneyString(item.credito) }}</span>
            </template>
            <template v-slot:[`item.debito`]="{ item }">
              <span>{{ helpers.floatToMoneyString(item.debito) }}</span>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col cols="3" md="3">
                    <strong>Usuario alta:</strong>
                    {{ item.usuAlta }}
                  </v-col>
                  <v-col cols="3" md="3">
                    <strong>Fecha alta:</strong>
                    {{ item.fecAlta }}
                  </v-col>
                  <v-col cols="3" md="3">
                    <strong>Usuario modificación:</strong>
                    {{ item.usuModi }}
                  </v-col>
                  <v-col cols="3" md="3">
                    <strong>Fecha modificación:</strong>
                    {{ item.fecModi }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" md="3">
                    <strong>Usuario concilia:</strong>
                    {{ item.usuConcilia }}
                  </v-col>
                  <v-col cols="3" md="3">
                    <strong>Fecha concilia:</strong>
                    {{ item.fecConcilia }}
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="6" md="6">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4"></v-col>
                  <v-col cols="1" align="end">
                    <v-btn
                      color="primary"
                      @click="openModalEditEstado()"
                      class="to-right"
                      :disabled="registrosSeleccionados.length == 0"
                      small
                    >
                      Editar estados
                    </v-btn>
                  </v-col>
                  <v-col cols="1" align="end">
                    <v-btn
                      color="primary"
                      @click="openModalEdit()"
                      class="to-right"
                      small
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModalEdit(item, true)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver movimiento bancario</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-menu offset-y>
                    <template
                      v-slot:activator="{ on: menuOn, attrs: menuAttrs }"
                    >
                      <v-icon
                        v-bind="{ ...attrs, ...menuAttrs }"
                        v-on="{ ...on, ...menuOn }"
                        size="20"
                      >
                        {{ editIcon }}
                      </v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item @click="openModalEdit(item)">
                        <v-list-item-title
                          >Editar movimiento bancario</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item @click="goToEditEstadoIndividual(item)">
                        <v-list-item-title>Editar estado</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteMovBancModal(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <!-- Importes generales -->
        <v-row class="mt-3">
          <v-col cols="12" md="2" class="d-flex py-0 pr-0">
            <v-text-field
              v-model.trim="saldoInicial"
              label="Saldo inicial"
              outlined
              persistent-hint
              disabled
              filled
              readonly
              dense
              class="right-input"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" class="d-flex py-0 pl-1 pr-0">
            <v-text-field
              v-model.trim="totalDebitos"
              label="Total débitos"
              outlined
              persistent-hint
              disabled
              filled
              readonly
              dense
              class="right-input"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" class="d-flex py-0 pl-1 pr-0">
            <v-text-field
              v-model.trim="totalCreditos"
              label="Total créditos"
              outlined
              persistent-hint
              disabled
              filled
              readonly
              dense
              class="right-input"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" class="d-flex py-0 pl-1 pr-0">
            <v-text-field
              v-model.trim="saldoFinal"
              label="Saldo final"
              outlined
              persistent-hint
              disabled
              filled
              readonly
              dense
              class="right-input"
            ></v-text-field>
          </v-col>
          <!--  Listado resumen -->
          <v-col cols="12" align="right" md="2" class="py-0 pl-0 pr-2">
            <v-tooltip top>
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  class="fontsize px-2"
                  v-bind="attrs"
                  outlined
                  :disabled="
                    movBancariosItems.length == 0 || loadingListadoResumen
                  "
                  @click="exportListadoResumen()"
                  :loading="loadingListadoResumen"
                >
                  Listado resumen
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
          <!-- Exportar detalle -->
          <v-col cols="12" align="right" md="2" class="py-0 pl-0 pr-4">
            <v-tooltip top>
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  class="fontsize px-2"
                  v-bind="attrs"
                  outlined
                  :disabled="movBancariosItems.length == 0"
                  @click="exportExcelModelo"
                >
                  Exportar detalle
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-0 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <!-- MODAL edicion de estados -->
    <v-dialog
      v-if="isOpenModalEditEstado"
      v-model="isOpenModalEditEstado"
      max-width="40%"
      @keydown.esc="closeAndReloadMovimientos"
      persistent
    >
      <EditEstadoMovBanc
        @closeAndReloadMovimientos="closeAndReloadMovimientos"
        :comprobantesToEditEstados="this.comprobantesToEditEstados"
      ></EditEstadoMovBanc>
    </v-dialog>
    <!-- Edicion de mov bancario general -->
    <v-dialog
      v-if="isOpenModalEdit"
      v-model="isOpenModalEdit"
      max-width="76%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditMovimientoBancario
        @closeAndReload="closeAndReload"
        :editMovimientoItem="editMovimientoItem"
        :onlySeeVista="onlySee"
      ></EditMovimientoBancario>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'42%'"
      :isLoading="loadingBtnDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import helpersExcel from "@/utils/helpers/importExportExcel";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";
import helpers from "@/utils/helpers.js";
import EditMovimientoBancario from "@/components/modules/fondos/EditMovimientoBancario.vue";
import EditEstadoMovBanc from "@/components/modules/fondos/EditEstadoMovBanc.vue";
import HistorialEstadosMovBanc from "@/components/modules/fondos/HistorialEstadosMovBanc.vue";

export default {
  name: "MovimientosBancarios",
  components: {
    PageHeader,
    FiltersSelected,
    Ayuda,
    EditMovimientoBancario,
    DeleteDialog,
    EditEstadoMovBanc,
    HistorialEstadosMovBanc,
  },
  directives: { mask },
  data: () => ({
    rules: rules,
    helpers: helpers,
    optionCode: enums.webSiteOptions.MOVIMIENTOS_BANCARIOS,
    title: "Movimientos bancarios",
    titleDelete: "",
    titleDeleteFirst: "¿Desea eliminar el movimiento bancario?",
    titleDeleteSecond:
      "El depósito está asociado con más de un movimiento. ¿Desea eliminarlos a todos?",
    searchIcon: enums.icons.SEARCH,
    calendarIcon: enums.icons.CALENDAR,
    deleteIcon: enums.icons.DELETE,
    seeIcon: enums.icons.SEE,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    mSCloseBoxIcon: enums.icons.CLOSE_BOX,
    checkBoxBlankOutlineIcon: enums.icons.CHECKBOX_BLANK_OUTLINE,
    editIcon: enums.icons.EDIT,
    search: "",
    showFilters: true,
    onlySee: false,
    filtersApplied: [],
    registrosSeleccionados: [],
    allowedActions: null,
    movBancariosItems: [],
    tipoFechaSelected: null,
    tiposFechasItems: [
      {
        id: 1,
        value: "Movimiento",
        value2: "C",
      },
      {
        id: 2,
        value: "Emisión",
        value2: "E",
      },
      {
        id: 3,
        value: "Conciliación",
        value2: "O",
      },
    ],
    menuFechaDesde: null,
    fechaDesde: null,
    fechaDesdeSelected: null,
    menuFechaHasta: null,
    fechaHasta: null,
    fechaHastaSelected: null,
    saldoInicial: null,
    saldoFinal: null,
    totalDebitos: null,
    totalCreditos: null,
    numeroDeCompDesde: null,
    numeroDeCompHasta: null,
    editMovimientoItem: null,
    showHelp: false,
    showIcon: true,
    showExpand: false,
    expanded: [],
    comprobantesToEditEstados: [],
    isLoadingTable: false,
    loadingListadoResumen: false,
    isOpenModalEdit: false,
    isFormValid: true,
    showDeleteModal: false,
    loadingBtnDelete: false,
    idToDelete: null,
    cuentasBancariasCorrect: true,
    isOpenModalEditEstado: false,
    headersMovBancarios: [
      {
        text: "Tipo de movimiento",
        sortable: true,
        value: "tipoMovimiento",
      },
      {
        text: "Fecha mov.",
        sortable: true,
        value: "fechaMov",
      },
      {
        text: "Fecha emisión",
        sortable: true,
        value: "fechaEmision",
      },
      {
        text: "N° comp.",
        sortable: true,
        value: "numero",
      },
      {
        text: "Débito",
        sortable: true,
        value: "debito",
        align: "end",
      },
      {
        text: "Crédito",
        sortable: true,
        value: "credito",
        align: "end",
      },
      {
        text: "Estado",
        sortable: true,
        value: "estado",
      },
      {
        text: "Cuenta bancaria",
        sortable: true,
        value: "cuentaBancaria",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        width: "9%",
        align: "end",
      },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
        align: "start",
      },
    ],
    tipoMovimientoSelect: [],
    tipoMovimientoItems: [],
    estadoBancarioSelect: [],
    estadosBancariosItems: [],
    cuentaBancariaSelect: null,
    cuentaBancariaItems: [],
  }),
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.setSelects();
  },
  computed: {
    multiselectIconTipoMov() {
      if (this.selectAllTipoMov) return this.mSCloseBoxIcon;
      return this.checkBoxBlankOutlineIcon;
    },
    selectAllTipoMov() {
      return (
        this.tipoMovimientoSelect &&
        this.tipoMovimientoSelect.length === this.tipoMovimientoItems.length
      );
    },
    multiselectIconEstadoBancario() {
      if (this.selectAllEstadoBancario) return this.mSCloseBoxIcon;
      return this.checkBoxBlankOutlineIcon;
    },
    selectAllEstadoBancario() {
      return (
        this.estadoBancarioSelect &&
        this.estadoBancarioSelect.length === this.estadosBancariosItems.length
      );
    },
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getTiposMovBancarios: "fondos/getTiposMovBancarios",
      getEstadosMovBancarios: "fondos/getEstadosMovBancarios",
      getCtasBancariasMovBancarios: "fondos/getCtasBancariasMovBancarios",
      getMovimientosBancariosFilter: "fondos/getMovimientosBancariosFilter",
      eliminarMovimientoBancario: "fondos/eliminarMovimientoBancario",
      exportarReporteListadoResumen: "fondos/exportarReporteListadoResumen",
    }),
    async setSelects() {
      this.tipoFechaSelected = this.tiposFechasItems.find((x) => x.id == 1);
      const tiposMovBancarios = await this.getTiposMovBancarios();
      this.tipoMovimientoItems = tiposMovBancarios;
      this.tipoMovimientoSelect = tiposMovBancarios;
      const estadosBancarios = await this.getEstadosMovBancarios();
      this.estadosBancariosItems = estadosBancarios;
      this.estadoBancarioSelect = estadosBancarios;
      const cuentasBancarias = await this.getCtasBancariasMovBancarios();
      this.cuentaBancariaItems = cuentasBancarias;
      this.setFecha();
    },
    setFecha() {
      let fecha = new Date();
      this.fechaHasta = fecha.toISOString().substring(0, 10);
      this.fechaHastaSelected = helpers.formatDate(this.fechaHasta);
      fecha.setMonth(fecha.getMonth() - 1);
      this.fechaDesde = fecha.toISOString().substring(0, 10);
      this.fechaDesdeSelected = helpers.formatDate(this.fechaDesde);
    },
    async applyFilters() {
      this.registrosSeleccionados = [];
      let cuentaString = "";
      let estadosString = "";
      let movimientosString = "";
      if (this.cuentaBancariaSelect.length > 0) {
        cuentaString = this.cuentaBancariaSelect.map((x) => x.bctaId).join(",");
      }
      if (this.estadoBancarioSelect.length > 0)
        estadosString = this.estadoBancarioSelect.map((x) => x.mbeId).join(",");
      if (this.tipoMovimientoSelect.length > 0)
        movimientosString = this.tipoMovimientoSelect
          .map((x) => x.tmbId)
          .join(",");

      this.isLoadingTable = true;
      this.customizeFiltersApplied();
      this.showFilters = false;
      const filters = {
        listaBCtaId: cuentaString,
        listatmbId: movimientosString,
        listaMbeId: estadosString,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        tipoFecha:
          this.tipoFechaSelected == null ? "" : this.tipoFechaSelected.value2,
        filtrarNroComp:
          this.numeroDeCompDesde == null || this.numeroDeCompDesde == ""
            ? false
            : true,
        nroCompDesde:
          this.numeroDeCompDesde == null ? "" : String(this.numeroDeCompDesde),
        nroCompHasta:
          this.numeroDeCompHasta == null ? "" : String(this.numeroDeCompHasta),
        monedaId: null,
      };
      try {
        const response = await this.getMovimientosBancariosFilter(filters);
        this.movBancariosItems = response.listaMov;
        this.saldoInicial = response.saldoInicial;
        this.saldoFinal = response.saldoFinal;
        this.totalDebitos = response.totalDebito;
        this.totalCreditos = response.totalCredito;
        this.isLoadingTable = false;
      } catch (error) {
        this.isLoadingTable = false;
      }
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    changeCuentaBancaria(val) {
      if (val.length > 0) {
        const monedaReferencia = this.cuentaBancariaSelect[0].monedaId;
        const sameCtasBanc = this.cuentaBancariaSelect.every(
          (item) => item.monedaId == monedaReferencia
        );
        if (!sameCtasBanc) {
          this.cuentasBancariasCorrect = false;
          this.isFormValid = false;
          this.setAlert({
            type: "warning",
            message:
              "Las cuentas bancarias seleccionadas, tienen monedas diferentes entre si.",
          });
        } else {
          this.cuentasBancariasCorrect = true;
          if (
            this.fechaDesdeSelected != null &&
            this.fechaHastaSelected != null
          )
            this.isFormValid = true;
        }
      } else {
        this.cuentasBancariasCorrect = true;
        this.cuentaBancariaSelect = null;
      }
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.tipoFechaSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "tipoFechaSelected",
          label: "Tipo de fecha",
          model: this.tipoFechaSelected.value,
        });
      }
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "fechaDesdeSelected",
          label: "Fecha desde",
          model: this.fechaDesdeSelected,
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "fechaHastaSelected",
          label: "Fecha hasta",
          model: this.fechaHastaSelected,
        });
      }
      if (this.cuentaBancariaSelect.length == 1) {
        this.filtersApplied.splice(3, 1, {
          key: "cuentaBancariaSelect",
          label: "Cuenta",
          model: this.cuentaBancariaSelect[0].bctaNombre,
        });
      } else if (this.cuentaBancariaSelect.length > 1) {
        this.filtersApplied.splice(3, 1, {
          key: "cuentaBancariaSelect",
          label: "Cuentas",
          model: "Todos",
        });
      }
      if (this.estadoBancarioSelect.length == 1) {
        this.filtersApplied.splice(4, 1, {
          key: "estadoBancarioSelect",
          label: "Estado",
          model: this.estadoBancarioSelect[0].mbeNombre,
        });
      } else if (this.estadoBancarioSelect.length > 1) {
        this.filtersApplied.splice(4, 1, {
          key: "estadoBancarioSelect",
          label: "Estados",
          model: "Todos",
        });
      }
      if (this.numeroDeCompDesde) {
        this.filtersApplied.splice(5, 1, {
          key: "numeroDeCompDesde",
          label: "Número desde",
          model: this.numeroDeCompDesde,
        });
      }
      if (this.numeroDeCompHasta) {
        this.filtersApplied.splice(6, 1, {
          key: "numeroDeCompHasta",
          label: "Número hasta",
          model: this.numeroDeCompHasta,
        });
      }
      if (this.tipoMovimientoSelect.length == 1) {
        this.filtersApplied.splice(8, 1, {
          key: "tipoMovimientoSelect",
          label: "Tipo de movimiento",
          model: this.tipoMovimientoSelect[0].tmbNombre,
        });
      } else if (this.tipoMovimientoSelect.length > 1) {
        this.filtersApplied.splice(8, 1, {
          key: "tipoMovimientoSelect",
          label: "Tipos de movimientos",
          model: "Todos",
        });
      }
    },
    openModalEdit(item, onlySee) {
      this.isOpenModalEdit = true;
      this.editMovimientoItem = item;
      this.onlySee = onlySee;
    },
    openModalEditEstado() {
      if (this.registrosSeleccionados.length > 0) {
        this.comprobantesToEditEstados = this.registrosSeleccionados;
        this.isOpenModalEditEstado = true;
      } else {
        this.setAlert({
          type: "warning",
          message: "Debe seleccionar comprobantes",
        });
      }
    },
    closeAndReload() {
      this.isOpenModalEdit = false;
      if (
        this.cuentaBancariaSelect?.length != 0 &&
        this.fechaDesdeSelected != null &&
        this.fechaHastaSelected != null
      )
        this.applyFilters();
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    deleteMovBancModal(item) {
      if (item.mbEncaId > 0) {
        this.titleDelete = this.titleDeleteSecond;
      } else {
        this.titleDelete = this.titleDeleteFirst;
      }
      this.showDeleteModal = true;
      this.idToDelete = item;
    },
    async confirmDelete() {
      this.loadingBtnDelete = true;
      try {
        const data = {
          mbId: this.idToDelete.mbId,
          mbEncaId: this.idToDelete.mbEncaId,
        };
        const response = await this.eliminarMovimientoBancario(data);
        if (response) {
          this.showDeleteModal = false;
          this.setAlert({
            type: "success",
            message: "Movimiento eliminado con exito",
          });
          this.loadingBtnDelete = false;
          this.applyFilters();
        }
      } catch (error) {
        this.showDeleteModal = false;
        this.loadingBtnDelete = false;
      }
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    selectAllToggle() {
      if (this.registrosSeleccionados.length < this.movBancariosItems.length) {
        this.registrosSeleccionados = this.movBancariosItems;
      } else {
        this.registrosSeleccionados = [];
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    tipoMovimientoToggle() {
      this.$nextTick(() => {
        if (this.selectAllTipoMov) this.tipoMovimientoSelect = [];
        else this.tipoMovimientoSelect = this.tipoMovimientoItems;
      });
    },
    estadoBancarioToggle() {
      this.$nextTick(() => {
        if (this.selectAllEstadoBancario) this.estadoBancarioSelect = [];
        else this.estadoBancarioSelect = this.estadosBancariosItems;
      });
    },
    exportExcelModelo() {
      let result = [];
      this.movBancariosItems?.forEach((x) =>
        result.push({
          ["Tipo de movimiento"]: x.tipoMovimiento,
          ["Fecha de movimiento"]: x.fechaMov,
          ["Fecha de emisión"]: x.fechaEmision,
          ["N° de comprobante"]: x.numero,
          ["Importe débito"]: x.debito,
          ["Importe crédito"]: x.credito,
          ["Estado"]: x.estado,
          ["Cuenta bancaria"]: x.cuentaBancaria,
          ["Moneda"]: x.monedaNombre,
          ["Cotización"]: x.cotizacion,
          ["Empresa"]: x.empresa,
          ["A orden de"]: x.aOrdenDe,
          ["Fecha de conciliación"]: x.fecConcilia,
          ["Observaciones"]: x.observaciones,
          ["N° de OP/pagos varios"]: x.op,
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(formato, "Movimientos bancarios");
    },
    async exportListadoResumen() {
      this.loadingListadoResumen = true;
      const data = {
        listaMov: this.movBancariosItems,
        fechaDesde: this.fechaDesdeSelected,
        fechaHasta: this.fechaHastaSelected,
        saldoInicial: this.saldoInicial,
        estados: this.estadoBancarioSelect
      };
      try {
        await this.exportarReporteListadoResumen(data);
      } catch (error) {
      } finally {
        this.loadingListadoResumen = false;
      }
    },
    goToEditEstadoIndividual(item) {
      this.comprobantesToEditEstados = [];
      this.registrosSeleccionados = [];
      this.comprobantesToEditEstados.push(item);
      this.isOpenModalEditEstado = true;
    },
    async closeAndReloadMovimientos() {
      this.isOpenModalEditEstado = false;
      this.applyFilters();
    },
  },
};
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
