<template>
  <v-card>
    <v-container>
      <v-card-title class="mt-n3">
        <span class="primary--text">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headersEstados"
          :items="estadosItems"
          class="elevation-1"
          :loading="isLoadingTable"
          item-key="mbLogId"
          :hide-default-footer="estadosItems.length <= 10"
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions class="mt-n4 pr-4 mb-n2">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "HistorialEstadosMovBanc",
  props: {
    mbIdToHistorialEstado: {
      type: Number,
    },
  },
  data: () => ({
    formTitle: "Historial de cambios de estado",
    estadosItems: [],
    headersEstados: [
      {
        text: "Estado anterior",
        sortable: true,
        value: "estAnterior",
      },
      {
        text: "Estado nuevo",
        sortable: true,
        value: "estNuevo",
      },
      {
        text: "Usuario",
        sortable: true,
        value: "usuario",
      },
      {
        text: "Fecha",
        sortable: true,
        value: "fechaCambio",
      },
      {
        text: "Hora",
        sortable: true,
        value: "horaCambio",
      },
    ],
    isLoadingTable: false,
  }),
  created() {
    this.setPantalla();
  },
  methods: {
    ...mapActions({
      getHistorialEstadosMovBanc: "fondos/getHistorialEstadosMovBanc",
    }),
    async setPantalla() {
      this.isLoadingTable = true;
      const historialEstados = await this.getHistorialEstadosMovBanc(this.mbIdToHistorialEstado);
      this.estadosItems = historialEstados;
      this.isLoadingTable = false;
    },
    closeModal() {
      this.$emit("closeHistorialEstados");
    }
  },
};
</script>

<style scoped>
</style>